<template>
  <div class="p-pegas">
    <div class="card-table">
      <div class="card-table-body ifon-add-title-block">
        <el-row :gutter="20">
          <el-col :span="16">
            <div class="caz-blocks-sarcho-title">
              <div class="content-title d-flex align-center mr-2">
                {{$t('message.synchronization')}}
              </div>
              <div class="block-sarche">
                <div class="header__search d-flex">
                  <el-input
                    placeholder="поиск"
                    prefix-icon="el-icon-search"
                    v-model="sarcheBlocks"
                    size="small"
                    round
                  ></el-input>
                  <!-- end input -->
                </div>
              </div>
            </div>
          </el-col>

          <el-col :span="8" class="flex-style text-right">
            <el-button size="small" class="btu-color" @click="appModal = true">
              {{$t('message.create')}}
            </el-button>
            <el-dropdown class="setting-cheek ml-2">
              <el-button
                class="padding_none"
                size="small"
                icon="el-icon-open"
              ></el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="(column, index) in columns"
                  :key="index"
                >
                  <el-checkbox
                    :checked="column.show"
                    @change="column.show = !column.show"
                    >{{ column.title }}</el-checkbox
                  >
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </el-col>
        </el-row>
      </div>
      <!-- end ifon-add-title-block -->

      <div class="card-table-header table-crm-smart">
        <table class="table-my-code table-bordered">
          <thead>
            <tr>
              <th class="w50p" v-if="columns.id.show">
                {{ columns.id.title }}
              </th>

              <th v-if="columns.nameUsers.show">
                {{ columns.nameUsers.title }}
              </th>

              <th v-if="columns.idevices.show">
                {{ columns.idevices.title }}
              </th>

              <th v-if="columns.subdivisions.show">
                {{ columns.subdivisions.title }}
              </th>

              <th v-if="columns.users.show">
                {{ columns.users.title }}
              </th>

              <th v-if="columns.data.show">
                {{ columns.data.title }}
              </th>

              <th v-if="columns.settings.show">
                {{ columns.settings.title }}
              </th>
            </tr>

            <tr class="filter_sorche">
              <th v-if="columns.id.show">
                <el-input
                  clearable
                  size="mini"
                  v-model="filterForm.id"
                  :placeholder="columns.id.title"
                  class="id_input"
                ></el-input>
              </th>

              <th v-if="columns.nameUsers.show">
                <el-input
                  clearable
                  size="mini"
                  v-model="filterForm.nameUsers"
                  :placeholder="columns.nameUsers.title"
                ></el-input>
              </th>

              <th v-if="columns.idevices.show">
                <el-input
                  clearable
                  size="mini"
                  v-model="filterForm.idevices"
                  :placeholder="columns.idevices.title"
                ></el-input>
              </th>

              <th v-if="columns.subdivisions.show">
                <el-select
                  v-model="filterForm.subdivisions"
                  filterable
                  clearable
                  :placeholder="columns.subdivisions.title"
                  size="mini"
                >
                  <el-option label="Активний" value="shanghai"></el-option>
                  <el-option label="Не астивний" value="beijing"></el-option>
                </el-select>
              </th>

              <th v-if="columns.users.show">
                <el-select
                  v-model="filterForm.users"
                  filterable
                  clearable
                  :placeholder="columns.users.title"
                  size="mini"
                >
                  <el-option label="Активний" value="shanghai"></el-option>
                  <el-option label="Не астивний" value="beijing"></el-option>
                </el-select>
              </th>

              <th v-if="columns.data.show">
                <el-date-picker
                  :placeholder="columns.data.title"
                  v-model="filterForm.data"
                  size="mini"
                >
                </el-date-picker>
              </th>

              <th class="settinW" v-if="columns.settings.show"></th>
            </tr>
          </thead>

          <transition-group name="flip-list" tag="tbody">
            <tr v-for="user in list" :key="user.id" class="cursor-pointer">
              <td v-if="columns.id.show">{{ user.id }}</td>

              <td v-if="columns.nameUsers.show">
                {{ user.nameUsers }}
              </td>

              <td v-if="columns.idevices.show">
                {{ user.idevices }}
              </td>

              <td v-if="columns.subdivisions.show">
                {{ user.subdivisions }}
              </td>

              <td v-if="columns.users.show">
                {{ user.users }}
              </td>

              <td v-if="columns.data.show">{{ user.data }}</td>

              <td v-if="columns.settings.show" class="settings-td">
                <el-dropdown :hide-on-click="false" class="table-bottons-right">
                  <span class="el-dropdown-link more_icons">
                    <i class="el-icon-arrow-down el-icon-more-outline"></i>
                  </span>
                  <el-dropdown-menu
                    slot="dropdown"
                    class="dropdown-menumy-style"
                  >
                    <el-dropdown-item icon="el-icon-edit">
                      {{$t("message.edit")}}
                    </el-dropdown-item>
                    <el-dropdown-item icon="el-icon-delete">
                      {{$t("message.delete")}}
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </td>
            </tr>
          </transition-group>
        </table>
        <div class="my___pagination">
          <Pagination />
        </div>
      </div>

      <div class="app-modal app-modal__full modal-widt-70">
        <el-dialog :visible.sync="appModal" :fullscreen="true" width="">
          <div
            @click="appModal = false"
            class="close-modal-stick d-flex align-center p-fixed text-white p-2 font-bold pointer transition"
          >
            <div class="close-modal-icon text-center rounded-circle transition">
              <i class="el-icon-close"></i>
            </div>
            <span class="close-modal-text text-uppercase ml-2"></span>
          </div>
          <CrmCreate />
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Pagination from "@/components/pagination";
import CrmCreate from "./components/crm-create";
export default {
  name: "synchronization",
  components: {
    CrmCreate,
    Pagination,
  },

  data() {
    return {
      appModal: false,
      currentPage2: 5,
      checkList: [],
      form: {},
      value2: "",
      value3: "",
      value4: "",
      value5: "",
      sarcheBlocks: "",
      filterForm: {
        id: "",
        nameUsers: "",
        idevices: "",
        subdivisions: "",
        users: "",
        data: "",
      },
      list: [
        {
          id: "1",
          nameUsers: "",
          idevices: "",
          subdivisions: "",
          users: "",
          data: "12.12.2020",
        },
        {
          id: "2",
          nameUsers: "",
          idevices: "",
          subdivisions: "",
          users: "",
          data: "12.12.2020",
        },
        {
          id: "3",
          nameUsers: "",
          idevices: "",
          subdivisions: "",
          users: "",
          data: "12.12.2020",
        },
        {
          id: "4",
          nameUsers: "",
          idevices: "",
          subdivisions: "",
          users: "",
          data: "12.12.2020",
        },
        {
          id: "5",
          nameUsers: "",
          idevices: "",
          subdivisions: "",
          users: "",
          data: "12.12.2020",
        },
        {
          id: "6",
          nameUsers: "",
          idevices: "",
          subdivisions: "",
          users: "",
          data: "12.12.2020",
        },
        {
          id: "7",
          nameUsers: "",
          idevices: "",
          subdivisions: "",
          users: "",
          data: "12.12.2020",
        },
        {
          id: "8",
          nameUsers: "",
          idevices: "",
          subdivisions: "",
          users: "",
          data: "12.12.2020",
        },
        {
          id: "9",
          nameUsers: "",
          idevices: "",
          subdivisions: "",
          users: "",
          data: "12.12.2020",
        },
        {
          id: "10",
          nameUsers: "",
          idevices: "",
          subdivisions: "",
          users: "",
          data: "12.12.2020",
        },
        {
          id: "11",
          nameUsers: "",
          idevices: "",
          subdivisions: "",
          users: "",
          data: "12.12.2020",
        },
        {
          id: "12",
          nameUsers: "",
          idevices: "",
          subdivisions: "",
          users: "",
          data: "12.12.2020",
        },
        {
          id: "13",
          nameUsers: "",
          idevices: "",
          subdivisions: "",
          users: "",
          data: "12.12.2020",
        },
        {
          id: "14",
          nameUsers: "",
          idevices: "",
          subdivisions: "",
          users: "",
          data: "12.12.2020",
        },
        {
          id: "15",
          nameUsers: "",
          idevices: "",
          subdivisions: "",
          users: "",
          data: "12.12.2020",
        },
        {
          id: "16",
          nameUsers: "",
          idevices: "",
          subdivisions: "",
          users: "",
          data: "12.12.2020",
        },
        {
          id: "17",
          nameUsers: "",
          idevices: "",
          subdivisions: "",
          users: "",
          data: "12.12.2020",
        },
        {
          id: "18",
          nameUsers: "",
          idevices: "",
          subdivisions: "",
          users: "",
          data: "12.12.2020",
        },
        {
          id: "19",
          nameUsers: "",
          idevices: "",
          subdivisions: "",
          users: "",
          data: "12.12.2020",
        },
        {
          id: "20",
          nameUsers: "",
          idevices: "",
          subdivisions: "",
          users: "",
          data: "12.12.2020",
        },
      ],
      columns: {
        id: {
          show: true,
          title: "№",
          sortable: true,
          column: "id",
        },
        nameUsers: {
          show: true,
          title: "  Ф.И.О",
          sortable: true,
          column: "nameUsers",
        },
        idevices: {
          show: true,
          title: " Отдел",
          sortable: true,
          column: "idevices",
        },
        subdivisions: {
          show: true,
          title: " График работы",
          sortable: true,
          column: "subdivisions",
        },

        users: {
          show: true,
          title: " Синхронизация",
          sortable: true,
          column: "users",
        },

        data: {
          show: true,
          title: "Дата создания",
          sortable: true,
          column: "data",
        },
        settings: {
          show: true,
          title: "Профиль",
          sortable: false,
          column: "settings",
        },
      },
    };
  },
  // methods: {
  //   openDrawer() {
  //     this.appModal = !this.appModal;
  //   },
  // },
};
</script>

